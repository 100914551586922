<template>
  <associations-component />
</template>

<script>
import AssociationsComponent from "@/components/admin/catalogs/AssociationsComponent";

export default {
  name: "Associations",
  title: "Asociaciones | Baja California Health Tourism",
  components: { AssociationsComponent },
};
</script>

<style scoped></style>
